import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Flex,
  Box,
  Heading
} from '@chakra-ui/react'
import { RichText } from 'prismic-reactjs'
import PageHero from '../components/PageHero/PageHero'
import MobilePageHero from '../components/PageHero/MobilePageHero'
import SliceZone from '../components/SliceZone/SliceZone'
import { getImage } from 'gatsby-plugin-image'
import Tetons from '../components/PageHero/Tetons'
import ParallaxTitle from '../components/PageHero/ParallaxTitle'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Sidebar from '../components/Sidebar/Sidebar'
import MooseLottie from '../components/Footer/Moose/MooseLottie'
import { CustomLink } from '../utils/customLink'
import NewsCard from '../components/NewsCards/NewsCard'


const NewsPage= (props) => {
      // Explicitly delcare props and destructure the other properties:
      const { location, data, ...rest } = props;
      const pageLocation = location.pathname

  if (!data) return null; 

  const document = data.prismicStaticPage.data
  const backgroundImage = getImage(document.hero_image.localFile)
  const image = getImage(backgroundImage);

  const newsItem = data.allPrismicNews.edges;

  // causing build error
  // const menuItems = null || document.sidebar_menu?.document.data.body

  return (
    <Layout>
      <Seo title="City of Driggs News" />
      <PageHero 
        heroImage={image}
        photoCredit={document.hero_image.copyright}
        pageTitle={document.page_headline?.text}
      >
        <Flex w="100%" height="60vh" flexDirection="column" justifyContent="flex-end" paddingBottom="5%" alignItems="left">
          <ParallaxTitle>
            <Box px="4" zIndex="1">
              <Heading color="white" as="h1" fontSize="4rem" textTransform="uppercase">
                {document.page_title.text}
              </Heading>
            </Box>
          </ParallaxTitle>
        </Flex>
        <Tetons />
      </PageHero>
     <MobilePageHero 
        heroImage={image}
        photoCredit={document.hero_image.copyright}
        pageTitle={document.page_headline?.text}
      />
      <Box position="relative" zIndex="1">
      <Flex w="100%" h="100%" flexWrap="wrap" flexDirection={{base: 'column-reverse', lg: 'row'}}>
            <Box w={{base: '100%', lg: '25%'}}>
              <Sidebar
                // menuHeading={menuItems && document.sidebar_menu.document.data.menu_title.text}  
                // menuItems={menuItems}
                pageLocation={pageLocation}
              />
            </Box>
            <Box w={{base: '100%', lg: '75%'}} bg="white" p="8" mb={{base: '4rem', md: '36rem'}}>
            <Heading as="h2" variant="page-title">
              {document.page_title.text}
            </Heading>
            <Heading as="h3" fontSize="2rem" variant="page-subtitle">
              {document.subtitle.text}
            </Heading>

        <RichText render={document.page_content.richText} serializeHyperlink={CustomLink}/>
        <Flex flexWrap="wrap">
                  {newsItem.map((item, index) => {
                    return (
                      <NewsCard
                        image={getImage(item.node.data.featured_image.localFile)}
                        key={index}
                        title={item.node.data.post_title.text}
                        excerpt={item.node.data.post_excerpt.text}
                        url={item.node.url}
                        tags={item.node.tags}
                      />
                    )
                  })}
                </Flex>
        
          <SliceZone sliceZone={document.body}/>

      </Box>
      <MooseLottie />
        </Flex>
      </Box>
      
    </Layout>
  )
}



export const query = graphql`
{
  prismicStaticPage(uid: {eq: "news"}) {
    data {
      page_headline {
        text
      }
      subtitle {
        text
      }
      page_title {
        text
      }
      page_content {
        richText
      }
      sidebar_menu {
        document {
          ... on PrismicSidebarNavigation {
            id
            data {
              menu_title {
                text
              }
              body {
                ... on PrismicSidebarNavigationDataBodySidebarNavAccordionItem {
                  id
                  slice_type
                  primary {
                    title_link {
                      url
                    }
                    navigation_group_title {
                      text
                    }
                  }
                  items {
                    link {
                      url
                    }
                    item_title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
      body {
        ... on PrismicStaticPageDataBodyRichText {
          id
          slice_type
          primary {
            content {
              richText
            }
          }
        }
        ... on PrismicStaticPageDataBodyRawHtmlSlice {
          id
          slice_type
          primary {
            html_content {
              html
            }
          }
        }
        ... on PrismicStaticPageDataBodyMeetingTabs {
          id
          slice_type
          primary {
            zoom_meeting_link {
              text
            }
            vimeo_meeting_iframe_embed_code {
              html
            }
            upcoming_meeting_date(formatString: "MM/DD/YYYY")
            upcoming_meeting_agenda_packet {
              richText
            }
            previous_meeting_date(formatString: "MM/DD/YYYY")
            link_to_upcoming_meeting_packet_pdf {
              url
            }
            link_to_previous_meeting_packet_pdf {
              url
            }
            link_to_previous_meeting_minutes_pdf {
              url
            }
          }
        }
        ... on PrismicStaticPageDataBodyIframeSlice {
          id
          slice_type
          primary {
            iframe_source {
              text
            }
          }
        }
        ... on PrismicStaticPageDataBodyFormLinkModule {
          id
          slice_type
          primary {
            form_links_title {
              text
            }
          }
          items {
            form_links {
              url
              document {
                ... on PrismicFormLinkModule {
                  id
                  data {
                    link_to_pdf {
                      url
                    }
                    form_title {
                      text
                    }
                    form_link {
                      url
                    }
                    form_description {
                      text
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicStaticPageDataBodyContactCard {
          id
          slice_type
          primary {
            department_contact_information {
              text
            }
          }
          items {
            contact_title {
              text
            }
            contact_phone {
              text
            }
            contact_name {
              text
            }
            contact_extension
            contact_email {
              text
            }
          }
        }
        ... on PrismicStaticPageDataBodyBidRfpProjectAccordion {
          id
          slice_type
          primary {
            accordion_title {
              text
            }
            accordion_subtitle {
              text
            }
          }
          items {
            item_title {
              text
            }
            item_description {
              richText
            }
            accordion_item_id {
              text
            }
          }
        }
      }
      hero_image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
        alt
        copyright
      }
    }
  }
  allPrismicNews(
    limit: 50, sort: {fields: data___date_posted, order: DESC}
    filter: {data: {date_posted: {ne: null}}}
    ) {
    edges {
      node {
        uid
        url
        data {
          post_title {
            text
          }
          post_excerpt {
            text
          }
          featured_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.5
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  transformOptions: {cropFocus: CENTER}
                )
              }
            }
          }
          date_posted(formatString: "MM/DD/YYYY")
        }
        tags
      }
    }
  }
}
`

export default withPrismicPreview(NewsPage)
